/* styles.module.scss */
@import "/src/styles/variables.scss";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 60vh;
	text-align: center;
}

.title {
	margin-bottom: 1rem;
}

.message {
	/* You can change the color to your preference */
	margin-bottom: 2rem;
}

@media screen and (max-width: $L) {
	// .container {
	//     height: calc(100vh - 60px);
	// }
}
